
import DxTagBox from 'devextreme-vue/tag-box';
import DxSelectBox from 'devextreme-vue/select-box';
import ItemService from '../services/item-service';
import SpecialFieldService from '../services/special-field-service';
import ItemSpecialFieldService from '../services/item-special-field-service';

export default {
  components: {
    DxTagBox,
    DxSelectBox
  },
  data() {
    return {
      dataSourceItems: null,
      dataSourceSpecialFields: null,
      dataSourceItemSpecialFields: null,
      specialFields: [],   
      specialFieldsAssigned: [],
      itemspecialfields: null,
      items: null,
    };
  },
  created() {
    this.itemService = new ItemService();
    this.specialFieldService = new SpecialFieldService();
    this.itemSpecialFieldService = new ItemSpecialFieldService();
  },
  mounted() {    
    this.itemService.getArticulos().then(data => this.dataSourceItems=data);
    this.specialFieldService.getCampos().then((data) => {
      data.forEach(element => {
        this.specialFields.push(element);
      });
      this.dataSourceSpecialFields=this.specialFields;
    });
  },
  methods: {
    valueChanged(e) {
      console.log(e.value.id_articulo);

      this.itemSpecialFieldService.getCamposPorArticulo(e.value.id_articulo).then((data) => {
        console.log(data);
        this.itemspecialfields=data;
        //this.itemspecialfields=[{"id_campo":1,"nombre":"Volúmen"}];
      });

    },
    onValueChanged(e) {
        e.addedItems.forEach(element => {
          this.itemSpecialFieldService.createArticuloCampo({"id_articulo": this.items.id_articulo, "id_campo": element.id_campo}).then(() => {           
            console.log(element.id_campo);
          });
        });
        /*
        e.removedItems.forEach(element => {
          let obj = this.dataSourceSpecialFields.find(o => o.id_articulo === this.items.id_articulo && o.id_campo === element.id_campo);
          console.log(obj);
          
          this.itemSpecialFieldService.deleteArticuloCampo({"id_articulo_campo": this.items.id_articulo, "id_campo": element.id_campo}).then(() => {           
            console.log(element.id_campo);
          });
          
        });
        */
    }
  },
};
